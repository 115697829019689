(function (document) {

    let formatsSupported = {
        "avif": -1,
        "webp": -1,
    }

    const checkSupport = (height, className) => {
      if (height == 2) {
        formatsSupported[className] = true
      } else {
        formatsSupported[className] = false
      }
      let finished = 0;
      Object.values(formatsSupported).forEach((val) => {
        if (val !== -1) {
            finished++
        }
      })
      if (finished == Object.values(formatsSupported).length) {
        applySupport()
      }
    }

    const applySupport = () => {
        let d = document.documentElement
        if (formatsSupported.avif === true) {
            d.classList.add("bg-avif-supported")
        }
        if (formatsSupported.webp === true) {
            d.classList.add("img-webp-supported")
            if (formatsSupported.avif !== true) {
                d.classList.add("bg-webp-supported")
            }
        }
        if (formatsSupported.avif === false && formatsSupported.webp === false) {
            d.classList.add("bg-jpeg-supported")
            d.classList.add("img-png-supported")
            d.classList.add("img-jpeg-supported")
        }
    }
  
    var AVIF = new Image()
    AVIF.onload = AVIF.onerror = function () {
      checkSupport(AVIF.height, "avif")
    }
    AVIF.src =
      "data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A="
  
    var WebP = new Image()
    WebP.onload = WebP.onerror = function () {
      checkSupport(WebP.height, "webp")
    }
    WebP.src =
      "data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA"

  })(
    (window.sandboxApi &&
      window.sandboxApi.parentWindow &&
      window.sandboxApi.parentWindow.document) ||
      document
  );